export const listColumns = [
	{ key: 'rq', label: '日期' },
	{ key: 'fks', label: '访客数（人）' },
	{ key: 'lll', label: '浏览量（人）' },
	{ key: 'hyyh', label: '活跃用户（人）' },
	{ key: 'zzl', label: '增长量（人）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '日期',
		componentType: 'date',
		decorator: [
			'rq',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	// {
	// 	label: '创建时间',
	// 	componentType: 'daterange',
	// 	decorator: [
	// 		'createTime',
	// 		{
	// 			rules: [{ required: false, message: '' }],
	// 		},
	// 	],
	// },
]

export const formItemEdit = () => [
	{ label: '日期', componentType: 'date', decorator: ['rq',{rules: [{ required: true, message: '请输入日期' }],},],},
	{ label: '访客数（人）', componentType: 'inputNumber', decorator: ['fks',{rules: [{ required: true, message: '请输入访客数（人）' }],},],},
	{ label: '浏览量（人）', componentType: 'inputNumber', decorator: ['lll',{rules: [{ required: true, message: '请输入浏览量（人）' }],},],},
	{ label: '活跃用户（人）', componentType: 'inputNumber', decorator: ['hyyh',{rules: [{ required: true, message: '请输入活跃用户（人）' }],},],},
	{ label: '增长量（人）', componentType: 'inputNumber', decorator: ['zzl',{rules: [{ required: true, message: '请输入增长量（人）' }],},],},
	//
]
