export const listColumns = [
	{ key: 'name', label: '机房名称' },
	{ key: 'status', label: '状态' },
	{ key: 'address', label: '地址' },
	{ key: 'create_time', label: '创建时间' },

	//
]

export const formItemSearch = () => [
	{
		label: '机房名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '正常',
				label: '正常'
			}, {
				value: '异常',
				label: '异常'
			}
		]
	}
]

export const formItemEdit = () => [
	{ label: '状态', componentType: 'select', decorator: ['status',{rules: [{ required: true, message: '请输入状态' }],},],options: [
		{
			value: '正常',
			label: '正常'
		}, {
			value: '异常',
			label: '异常'
		}
	]},
	{ label: '地址', componentType: 'input', decorator: ['address',{rules: [{ required: true, message: '请输入地址' }],},],},
	{ label: '机房名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入机房名称' }],},],},
	//
]
