export const listColumns = [
	{ key: 'wlljs', label: '网络连接数（个）' },
	{ key: 'yzj', label: '云主机总量（台）' },
	{ key: 'cczyc', label: '存储资源池（PB）' },
	{ key: 'nczyc', label: '内存资源池（TB）' },
	{ key: 'cpu', label: 'CPU总量（核）' },
	{ key: 'ssdkfz', label: '实时带宽峰值（Bps)' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '网络连接数（个）', componentType: 'inputNumber', decorator: ['wlljs',{rules: [{ required: true, message: '请输入网络连接数（个）' }],},],},
	{ label: '云主机总量（台）', componentType: 'inputNumber', decorator: ['yzj',{rules: [{ required: true, message: '请输入云主机总量（台）' }],},],},
	{ label: '存储资源池（PB）', componentType: 'inputNumber', decorator: ['cczyc',{rules: [{ required: true, message: '请输入存储资源池（PB）' }],},],},
	{ label: '内存资源池（TB）', componentType: 'inputNumber', decorator: ['nczyc',{rules: [{ required: true, message: '请输入内存资源池（TB）' }],},],},
	{ label: 'CPU总量（核）', componentType: 'inputNumber', decorator: ['cpu',{rules: [{ required: true, message: '请输入CPU总量（核）' }],},],},
	{ label: '实时带宽峰值（Bps)', componentType: 'inputNumber', decorator: ['ssdkfz',{rules: [{ required: true, message: '请输入实时带宽峰值（Bps)' }],},],},
	//
]
