<template>
    <div :class="['form-contain', 'form-contain-' + type]">
    <el-form ref="editForm" :model="form" :inline="inline" :rules="rules" :label-width="labelWidth" :label-position="labelPosition" :size="size">
        <el-form-item v-for="(item, index) in formItemList" :key="index" :label="item.label" :prop="item.decorator[0]">
            <el-input v-if="item.componentType === 'input'" v-model="form[item.decorator[0]]" placeholder="请输入" style="width: 100%;"></el-input>
            <el-input-number v-if="item.componentType === 'inputNumber'" v-model="form[item.decorator[0]]" placeholder="请输入" style="width: 100%;"></el-input-number>
            <el-select v-if="item.componentType === 'select'" v-model="form[item.decorator[0]]" placeholder="请选择" style="width: 100%;">
                <el-option v-for="(option, i) in item.options" :key="i" :label="option.label" :value="option.value"></el-option>
            </el-select>
            <el-date-picker v-if="item.componentType === 'date'" v-model="form[item.decorator[0]]" type="date" placeholder="选择日期" style="width: 100%;" value-format="yyyy-MM-dd"></el-date-picker>
            <el-date-picker
                v-if="item.componentType === 'datetimerange'"
                v-model="form[item.decorator[0]]"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd hh:mm:ss"
            ></el-date-picker>
            <el-date-picker
                v-if="item.componentType === 'daterange'"
                v-model="form[item.decorator[0]]"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
            ></el-date-picker>
        </el-form-item>
       
        <el-form-item v-if="type === 'query'">
            <el-button type="primary" size="small" @click="onSubmit(1)" icon="el-icon-search">查询</el-button>
            <el-button plain size="small" @click="onSubmit()" icon="el-icon-refresh">重置</el-button>
        </el-form-item>
        </el-form>
        <div v-if="type === 'edit'">
            <el-button size="small" type="primary" @click="onSubmit(1)">提交</el-button>
            <el-button size="small" @click="onSubmit()">取消</el-button>
        </div>
    </div>
</template>
  
  <script>
  const formValueFormat = (obj) => {
    const newObj = JSON.parse(JSON.stringify(obj))
    Object.keys(obj).forEach(item => {
        if(!obj[item]) {
            delete newObj[item]
        }
    })
    return newObj
  }
  
  export default {
    name: 'CommonForm',

    data() {
        
        return {
            form: null,
            rules: []
        }
    },
  
    props: {
        formItemList: {
            type: Array,
            default: () => []
        },
        initData: {
            type: Object,
            default: () => {}
        },
        getData: {
            type: Function,
            default: () => {}
        },
        type: {
            type: String,
            default: 'edit'
        },
        labelPosition: {
            type: String,
            default: 'left'
        },
        size: {
            type: String,
            default: ''
        },
        labelWidth: {
            type: String,
            default: '150px'
        },
        inline: {
            type: Boolean,
            default: false
        },  
    },

    created() {
        this.form = {...this.initData}
        const rules = {}
        const keys = this.formItemList.map((item) => item.decorator[0])
        for(let i =0; i <= keys.length - 1; i ++) {
            rules[keys[i]] = this.formItemList[i].decorator[1].rules
        }
        this.rules = rules
        console.log('this.form:', this.form)
        console.log('this.rules:', this.rules)
        console.log('this.formItemList:', this.formItemList)
    },
  
    methods: {
      onSubmit(type){
        if(!type) {
            this.$refs.editForm.resetFields()
            this.$emit('submit', null)
            return
        }
        console.log('this.form:', this.form)
        this.$refs.editForm.validate(async valid => {
            console.log('valid:', valid)
            if (valid) {
                let params = {...this.form}
                if(this.form.createTime) {
                    delete params.createTime
                    params.start_time = this.form.createTime[0]
                    params.end_time = this.form.createTime[1]
                }
                this.$emit('submit', formValueFormat({...params}))
            }
        })
      }
    }
  }
  </script>
  <style lang="scss">
  .form-contain {
    display: flex;
    
    &-edit {
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
        .el-form {
            flex: 1;
        }
    }
    &-query {
        flex: 1;

    }
    
  }
</style>