<template>
	<div class="operationYzyData-index">
		<div class="data-show">
			<h3>云资源开通</h3>
			<ul>
				<li v-for="(item, index) in listColumns" :key="index" class="data-item data-item-operation-data flex-center-both" :style="'background-image:url('+require('@/assets/images/operation/'+(index+1)+'.png')+')'">
				<div class="flex-space-column">
					<p>{{ item.label }}</p>
					<h2>{{ data[item.key]}}</h2>
				</div>
				</li>
			</ul>
			<el-button type="primary" size="mini" @click="add" style="position: absolute;right: 15px; top: 15px;">{{data && data.id ? '编辑' : '新增'}}</el-button>
			</div>

		<el-drawer
			:title="drawerType ? '编辑' : '新增'"
			:visible.sync="drawerVisible"
			size="40%"
			destroy-on-close
			@close="hideDrawer"
			@closed="hideDrawerClosed"
			class="drawer-contain"
		>
			<div class="drawer-content">
				<CommonForm
					size="small"
					:formItemList="formItemEdit"
					:initData="initEditData"
					@submit="onEditSubmit"
				/>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { listColumns, formItemEdit } from './setting.js'
import { getListApi, addApi, updateApi } from './api'
import CommonForm from '@/components/CommonForm.vue'
import { formDataFormat } from '@/utils/common.js'

export default {
	name: 'OperationYzyDataIndex',

	components: { CommonForm },

	data() {
		return {
			initEditData: null,

			drawerVisible: false,
			drawerType: 0,
			drawerBtnLoading: false,


			data: {},

			listColumns,
		}
	},

	computed: {
		formItemEdit() {
			return formItemEdit(this)
		},
		editFieldsKeys() {
			return this.formItemEdit.map((item) => item.decorator[0])
		},
	},

	created() {
		this.getList()
	},

  methods: {
    async getList() {
			const params = { page: 1, pageSize: 10 }
			console.log('params:', params)
			const {
				data: { list },
			} = await getListApi(params)
			this.data = list[0] || {}
		},

    add() {
      if(this.data && this.data.id) {
        this.edit()
        return
      }
			this.drawerType = 0
			this.initEditData = formDataFormat(this.editFieldsKeys, {})
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
		},

		async edit() {
			this.drawerType = 1
			const data = this.data
			console.log('data:', data)
			this.initEditData = { ...formDataFormat(this.editFieldsKeys, data), id: this.data.id }
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
		},

		hideDrawer() {
			this.drawerVisible = false
		},

		hideDrawerClosed() {
			this.getList()
		},

    async onEditSubmit(values) {
			console.log('values:', values)
			if (!values) {
				this.drawerVisible = false
				return
			}

			this.drawerBtnLoading = true
			const api = values.id ? updateApi : addApi
			const params = { ...values }
			const { data } = await api(params, values.id)
			console.log('data:', data)
this.drawerVisible = false
this.$message.success(`${values.id ? '编辑' : '新增'}成功`)
			this.drawerBtnLoading = false
			this.getList()
		},
  },
}
</script>
