export const listColumns = [
	{ key: 'name', label: '类别名称' },
	{ key: 'zb', label: '资源类别占比（%）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '类别名称',
		componentType: 'select',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '云监控',
				label: '云监控'
			}, {
				value: '云主机',
				label: '云主机'
			}, {
				value: '云网络',
				label: '云网络'
			}]
	},
	// {
	// 	label: '创建时间',
	// 	componentType: 'daterange',
	// 	decorator: [
	// 		'createTime',
	// 		{
	// 			rules: [{ required: false, message: '' }],
	// 		},
	// 	],
	// },
]

export const formItemEdit = () => [
	{ label: '类别名称', componentType: 'select', decorator: ['name',{rules: [{ required: true, message: '请输入名称' }],},],options: [
		{
			value: '云监控',
			label: '云监控'
		}, {
			value: '云主机',
			label: '云主机'
		}, {
			value: '云网络',
			label: '云网络'
		}]},
	{ label: '资源类别占比（%）', componentType: 'inputNumber', decorator: ['zb',{rules: [{ required: true, message: '请输入资源类别占比（%）' }],},],},
	//
]
