export const listColumns = [
	{ key: 'aqyj', label: '安全预警（次）' },
	{ key: 'yzj', label: '云主机（个）' },
	{ key: 'syxt', label: '上云系统数（个）' },
	{ key: 'sykf', label: '上云客户（家）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '安全预警（次）', componentType: 'inputNumber', decorator: ['aqyj',{rules: [{ required: true, message: '请输入安全预警（次）' }],},],},
	{ label: '云主机（个）', componentType: 'inputNumber', decorator: ['yzj',{rules: [{ required: true, message: '请输入云主机（个）' }],},],},
	{ label: '上云系统数（个）', componentType: 'inputNumber', decorator: ['syxt',{rules: [{ required: true, message: '请输入上云系统数（个）' }],},],},
	{ label: '上云客户（家）', componentType: 'inputNumber', decorator: ['sykf',{rules: [{ required: true, message: '请输入上云客户（家）' }],},],},
	//
]
