export const listColumns = [
	{ key: 'status', label: '设备状态' },
	{ key: 'name', label: '设备名称' },
	{ key: 'sbbh', label: '设备编号' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		// options: [
		// 	{ value: 'UPS', label: 'UPS' },
		// 	{ value: '高压直流', label: '高压直流' },
		// 	{ value: '变压器', label: '变压器' },
		// 	{ value: '备用电源', label: '备用电源' },
		// 	{ value: '温湿传感器', label: '温湿传感器' },
		// 	{ value: '空调', label: '空调' },
		// 	{ value: '视频监控', label: '视频监控' }
		// ]
	},
	{
		label: '设备状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '通',
				label: '通'
			}, {
				value: '断',
				label: '断'
			}
		]
	},
]

export const formItemEdit = () => [
	{ label: '设备状态', componentType: 'select', decorator: ['status',{rules: [{ required: true, message: '请输入设备状态' }],},],options: [
		{
			value: '通',
			label: '通'
		}, {
			value: '断',
			label: '断'
		}
	]},
	{ label: '设备名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入设备名称' }],},],},
	{ label: '设备编号', componentType: 'input', decorator: ['sbbh',{rules: [{ required: true, message: '请输入设备编号' }],},],},
	//
]
