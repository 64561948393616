export const listColumns = [
	{ key: 'name', label: '名称' },
	{ key: 'times', label: '次数' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '次数', componentType: 'inputNumber', decorator: ['times',{rules: [{ required: true, message: '请输入次数' }],},],},
	{ label: '名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入名称' }],},],},
	//
]
