export const listColumns = [
	{ key: 'name', label: '工单名称' },
	{ key: 'dbh', label: '工单编号' },
	{ key: 'clsc', label: '处理时长（分钟）' },
	{ key: 'status', label: '状态' },
	{ key: 'dqclr', label: '当前处理人' },
	{ key: 'create_time', label: '创建时间' },
	//
]
export const formItemSearch = () => [
	{
		label: '工单名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '工单编号',
		componentType: 'input',
		decorator: [
			'dbh',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '未处理',
				label: '未处理'
			}, {
				value: '已完成',
				label: '已完成'
			}, {
				value: '异常',
				label: '异常'
			}
		]
	},
	// {
	// 	label: '创建时间',
	// 	componentType: 'daterange',
	// 	decorator: [
	// 		'createTime',
	// 		{
	// 			rules: [{ required: false, message: '' }],
	// 		},
	// 	],
	// },
]

export const formItemEdit = () => [
	{ label: '处理时长（分钟）', componentType: 'inputNumber', decorator: ['clsc',{rules: [{ required: true, message: '请输入处理时长' }],},],},
	{ label: '状态', componentType: 'select', decorator: ['status',{rules: [{ required: true, message: '请输入状态' }],},],options: [
		{
			value: '未处理',
			label: '未处理'
		}, {
			value: '已完成',
			label: '已完成'
		}, {
			value: '异常',
			label: '异常'
		}
	]},
	{ label: '当前处理人', componentType: 'input', decorator: ['dqclr',{rules: [{ required: true, message: '请输入当前处理人' }],},],},
	{ label: '工单名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入工单名称' }],},],},
	{ label: '工单编号', componentType: 'input', decorator: ['dbh',{rules: [{ required: true, message: '请输入工单编号' }],},],},
	//
]
