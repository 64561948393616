export const listColumns = [
	{ key: 'name', label: '名称' },
	{ key: 'ysy', label: '已使用' },
	{ key: 'zzy', label: '总资源' },
	{ key: 'rate', label: '使用率（%）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'select',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: 'cpu',
				label: 'cpu'
			}, {
				value: '内存',
				label: '内存'
			}, {
				value: '硬盘',
				label: '硬盘'
			}]
	},
]

export const formItemEdit = () => [
	{ label: '已使用', componentType: 'inputNumber', decorator: ['ysy', { rules: [{ required: true, message: '请输入已使用' }], },], },
	{ label: '总资源', componentType: 'inputNumber', decorator: ['zzy', { rules: [{ required: true, message: '请输入总资源' }], },], },
	{ label: '名称', componentType: 'select', decorator: ['name', { rules: [{ required: true, message: '请输入名称' }], },], options: [
		{
			value: 'cpu',
			label: 'cpu'
		}, {
			value: '内存',
			label: '内存'
		}, {
			value: '硬盘',
			label: '硬盘'
		}]},
	//
]
