export const listColumns = [
	{ key: 'name', label: '资源名称' },
	{ key: 'qtgds', label: '其他工单数' },
	{ key: 'sqgds', label: '申请工单数' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'select',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: 'cpu',
				label: 'cpu'
			}, {
				value: '内存',
				label: '内存'
			}, {
				value: '硬盘',
				label: '硬盘'
			}]
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '其他工单数', componentType: 'inputNumber', decorator: ['qtgds',{rules: [{ required: true, message: '请输入其他工单数' }],},],},
	{ label: '申请工单数', componentType: 'inputNumber', decorator: ['sqgds',{rules: [{ required: true, message: '请输入申请工单数' }],},],},
	{ label: '资源名称', componentType: 'select', decorator: ['name',{rules: [{ required: true, message: '请输入资源名称' }],},],options: [
		{
			value: 'cpu',
			label: 'cpu'
		}, {
			value: '内存',
			label: '内存'
		}, {
			value: '硬盘',
			label: '硬盘'
		}]},
	//
]
