export const listColumns = [
	{ key: 'gzs', label: '故障数' },
	{ key: 'jfyjl', label: '机房预警量' },
	{ key: 'jfzcs', label: '机房正常数' },
	{ key: 'jfsys', label: '机房使用数' },
	{ key: 'jfs', label: '机房数' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '故障数', componentType: 'inputNumber', decorator: ['gzs',{rules: [{ required: true, message: '请输入故障数' }],},],},
	{ label: '机房预警量', componentType: 'inputNumber', decorator: ['jfyjl',{rules: [{ required: true, message: '请输入机房预警量' }],},],},
	{ label: '机房正常数', componentType: 'inputNumber', decorator: ['jfzcs',{rules: [{ required: true, message: '请输入机房正常数' }],},],},
	{ label: '机房使用数', componentType: 'inputNumber', decorator: ['jfsys',{rules: [{ required: true, message: '请输入机房使用数' }],},],},
	{ label: '机房数', componentType: 'inputNumber', decorator: ['jfs',{rules: [{ required: true, message: '请输入机房数' }],},],},
	//
]
