<template>
  <div class="page-index">
    <div class="data-show">
      <h3>资源概览</h3>
      <ul>
        <!-- :style="'background-image:url('+require('@/assets/images/operation/'+(index+1)+'.png')+')'" -->
        <li v-for="(item, index) in listColumns" :key="index" class="data-item data-item-resource-data flex-center-both">
          <div class="flex-space-column">
            <p>{{ item.label }}</p>
            <h2>{{ data[item.key]}}</h2>
          </div>
        </li>
      </ul>
      <el-button type="primary" size="mini" @click="add" style="position: absolute;right: 15px; top: 15px;">{{data && data.id ? '编辑' : '新增'}}</el-button>
    </div>
    <el-tabs v-model="activeTab">
      <el-tab-pane label="资源管理" name="0"></el-tab-pane>
      <el-tab-pane label="产品使用情况管理" name="1"></el-tab-pane>
      <el-tab-pane label="资源类别" name="2"></el-tab-pane>
      <el-tab-pane label="告警管理" name="3"></el-tab-pane>
      <el-tab-pane label="资源申请工单管理" name="4"></el-tab-pane>
    </el-tabs>
    <resourceZygl v-if="activeTab === '0'" />
    <resourceCpsy v-if="activeTab === '1'" />
    <resourceZylb v-if="activeTab === '2'" />
    <resourceGjgl v-if="activeTab === '3'" />
    <resourceGdgl v-if="activeTab === '4'" />

    <el-drawer
			:title="drawerType ? '编辑' : '新增'"
			:visible.sync="drawerVisible"
			size="40%"
			destroy-on-close
			@close="hideDrawer"
			@closed="hideDrawerClosed"
			class="drawer-contain"
		>
			<div class="drawer-content">
				<CommonForm
					size="small"
					:formItemList="formItemEdit"
					:initData="initEditData"
					@submit="onEditSubmit"
				/>
			</div>
		</el-drawer>
  </div>
</template>

<script>
import resourceCpsy from "./resourceCpsy";
import resourceZylb from "./resourceZylb";
import resourceZygl from "./resourceZygl";
import resourceGjgl from "./resourceGjgl";
import resourceGdgl from "./resourceGdgl";

import { listColumns, formItemEdit } from './setting.js'
import { getListApi, addApi, updateApi } from './api'
import CommonForm from '@/components/CommonForm.vue'
import { formDataFormat } from '@/utils/common.js'

export default {
  name: "HomeIndex",

  components: { resourceCpsy, resourceZylb, resourceZygl, resourceGjgl, resourceGdgl, CommonForm },

  data() {
    return {
      activeTab: '0',

      data: {},

      listColumns,

      initEditData: null,
			drawerVisible: false,
			drawerType: 0,
			drawerBtnLoading: false,
    };
  },

  
  computed: {
		formItemEdit() {
			return formItemEdit(this)
		},
		editFieldsKeys() {
			return this.formItemEdit.map((item) => item.decorator[0])
		},
	},

	created() {
		this.getList()
	},

  methods: {
    async getList() {
			const params = { page: 1, pageSize: 10 }
			console.log('params:', params)
			const {
				data: { list },
			} = await getListApi(params)
			this.data = list[0] || {}
		},

    add() {
      if(this.data && this.data.id) {
        this.edit()
        return
      }
			this.drawerType = 0
			this.initEditData = formDataFormat(this.editFieldsKeys, {})
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
			console.log('this.drawerVisible:', this.drawerVisible)
		},

		async edit() {
			this.drawerType = 1
			const data = this.data
			console.log('data:', data)
			this.initEditData = { ...formDataFormat(this.editFieldsKeys, data), id: this.data.id }
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
		},

		hideDrawer() {
			this.drawerVisible = false
		},

		hideDrawerClosed() {
			this.getList()
		},

    async onEditSubmit(values) {
			console.log('values:', values)
			if (!values) {
				this.drawerVisible = false
				return
			}

			this.drawerBtnLoading = true
			const api = values.id ? updateApi : addApi
			const params = { ...values }
			const { data } = await api(params, values.id)
			console.log('data:', data)
this.drawerVisible = false
this.$message.success(`${values.id ? '编辑' : '新增'}成功`)
			this.drawerBtnLoading = false
			this.getList()
		},
  },
};
</script>