/*
 * @desc: ajax请求
 * @author: xugang
 * @date: 20200803
 * @example: 
 *  ① get:  export const getOrderList = ( params ) => ajax.get(`/order/list`, { params: params } )
 *  ② post: export const login = ( params ) => ajax.post(`/auth/login`, params)
 * 
 * @attention:
 *  Some API interfaces do not need to prompt the error information after returning the error status code
 *  So you can do it like this, `export const login = (params) => ajax.post(`/auth/login`, params, { noErrToast: true })`
 */

import Axios from 'axios'
import router from '@/router'
import { error } from '@/utils/action'
import { USER_INFO } from '@/utils/constant'

// A function to get token from localStorage
const getLocalToken = () => window.localStorage.getItem('token')

// Add a settoken method to the instance to dynamically add the latest token to the header after login, 
// and save the token in localstorage
const setToken = (token) => {
  instance.defaults.headers['Authorization'] = 'Bearer ' + token
  window.localStorage.setItem('token', token)
}

// A function to refresh token
const refreshToken = () => instance.post('/refreshtoken').then(res => res.data)

let isRefreshing = false // A refreshing flag
let requests = [] // The retrial queue, each item will be in the form of a function to be executed

// Create an Axios instance 
const instance = Axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/rest_api' : '/rest',
  timeout: 300000,
  headers: { 'Content-Type': 'application/json' }
})

const doResponseHandle = (data, response) => {
  const { code, message } = data
  if (code === 200) {
    return Promise.resolve(data)
  } else if (code !== 200) {
    if (code === 401) {
      const config = response.config
      if (!isRefreshing) {
        isRefreshing = true
        return refreshToken().then(res => {
          const { token } = res.data
          setToken(token)
          config.headers['Authorization'] = 'Bearer ' + token
          config.baseURL = ''
          
          // The token has been refreshed, and now all requests in the queue need to be retried
          requests.forEach(cb => cb(token))
          requests = []
          return instance(config)
        }).catch(res => {
          error(message || '登录失效')
          console.error('refreshtoken error =>', res)
          window.localStorage.removeItem(USER_INFO)
          window.localStorage.removeItem('token')
          router.replace('/auth/login')
        }).finally(() => {
          isRefreshing = false
        })
      } else {
        // Refreshing token, will return a promise that does not resolve
        return new Promise(resolve => {
          // Put the resolve into the queue, save it in the form of a function, 
          // and execute it directly after the token is refreshed
          requests.push((token) => {
            config.baseURL = ''
            config.headers['Authorization'] = 'Bearer ' + token
            resolve(instance(config))
          })
        })
      }
    }else{
      if(!response.config || !response.config.errToastNone) error(message || '服务器异常！请稍后重试...')
    }
    return Promise.reject(response)
  }
}

// 防止重复提交
let pendingArr = []
let cancelToken = Axios.CancelToken
let removePending = (config) => {
  if(pendingArr.length){
    for(let i in pendingArr){
      // 若当前请求在数组中存在时
      if(pendingArr[i].url === config.url + '&' + config.method) { 
        pendingArr[i].fn()        // 执行取消操作
        pendingArr.splice(i, 1)   // 把这条记录从数组中移除
      }
    }
  }
}

// request interceptor
instance.interceptors.request.use( config => {
  let token = null
  if (window.localStorage.getItem(USER_INFO)) {
    token = JSON.parse(window.localStorage.getItem(USER_INFO)).token
  }
  if (token) {
    config.headers.common['Authorization'] = 'Bearer ' + token
    if(config.url.indexOf('v5') >= 0) {
      config.baseURL = '/v5'
    }
    if(config.url.indexOf('v3') >= 0) {
      config.baseURL = '/v3'
    }
  }

  // 在一个ajax发送前执行一下取消操作
  removePending(config); 
  config.cancelToken = new cancelToken(c => {
    pendingArr.push({ url: config.url + '&' + config.method, fn: c });  
  });
  
  return config
}, (err) => {
  return Promise.reject(err)
})

// response interceptor
instance.interceptors.response.use( 
  response => {
    if (response.status !== 200) {
      if(!response.config || !response.config.errToastNone) error('服务器异常！请稍后重试...')
      return Promise.reject(response)
    }

    // 导出文件(指定使用二进制接收), 需先解析成json格式, 判断响应是否正确
    if (response.config.responseType && response.config.responseType === 'arraybuffer') {
      let enc = new TextDecoder('utf-8') 
      let json = JSON.parse(enc.decode(new Uint8Array(response.data)))
      if(json.code === 200){
        return Promise.resolve(response.data)
      }else{
        return doResponseHandle(json, response)
      }
    }else{
      return doResponseHandle(response.data, response)
    }
  }, 
  err => {
    if(err?.response?.status === 401) {
      error('登录失效')
      window.localStorage.removeItem(USER_INFO)
      window.localStorage.removeItem('token')
      router.replace('/auth/login')
    }

    if(err?.response?.status === 400) {
      error(err?.response?.data?.message || '服务器异常，请稍后重试')
    }
    return Promise.reject(err)
  }
)

export default instance