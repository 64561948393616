export const listColumns = [
	{ key: 'name', label: '名称' },
	{ key: 'ccsl', label: '存储速率（GB/S)' },
	{ key: 'cc', label: '存储（GB）' },
	{ key: 'sj', label: '数据（GB）' },
	{ key: 'zylx', label: '资源类型' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '资源类型',
		componentType: 'select',
		decorator: [
			'zylx',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '云数据',
				label: '云数据'
			}, {
				value: '云存储',
				label: '云存储'
			}, {
				value: '硬盘',
				label: '硬盘'
			}
		]
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入资源名称' }],},],},
	{ label: '存储速率（GB/S)', componentType: 'inputNumber', decorator: ['ccsl',{rules: [{ required: true, message: '请输入存储速率（GB/S)' }],},],},
	{ label: '存储（GB）', componentType: 'inputNumber', decorator: ['cc',{rules: [{ required: true, message: '请输入存储（GB）' }],},],},
	{ label: '数据（GB）', componentType: 'inputNumber', decorator: ['sj',{rules: [{ required: true, message: '请输入数据（GB）' }],},],},
	{ label: '资源类型', componentType: 'select', decorator: ['zylx',{rules: [{ required: true, message: '请输入资源类型' }],},],options: [
		{
			value: '云数据',
			label: '云数据'
		}, {
			value: '云存储',
			label: '云存储'
		}, {
			value: '硬盘',
			label: '硬盘'
		}
	]},
	
	//
]
