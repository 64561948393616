export const listColumns = [
	{ key: 'clrxm', label: '处理人姓名' },
	{ key: 'zymc', label: '资源名称' },
	{ key: 'time', label: '处理时间' },
	{ key: 'status', label: '处理状态' },
	{ key: 'clrsfz', label: '处理人身份证号' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '资源名称',
		componentType: 'select',
		decorator: [
			'zymc',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: 'cpu',
				label: 'cpu'
			}, {
				value: '内存',
				label: '内存'
			}, {
				value: '硬盘',
				label: '硬盘'
			}]
	},
	{
		label: '处理状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '待处理',
				label: '待处理'
			}, {
				value: '处理中',
				label: '处理中'
			}, {
				value: '已处置',
				label: '已处置'
			}]
	},
]

export const formItemEdit = () => [
	{ label: '处理时间', componentType: 'date', decorator: ['time',{rules: [{ required: true, message: '请输入处理时间' }],},],},
	{ label: '处理状态', componentType: 'select', decorator: ['status',{rules: [{ required: true, message: '请输入处理状态' }],},],options: [
		{
			value: '待处理',
			label: '待处理'
		}, {
			value: '处理中',
			label: '处理中'
		}, {
			value: '已处置',
			label: '已处置'
		}]},
	{ label: '处理人身份证号', componentType: 'input', decorator: ['clrsfz',{rules: [{ required: true, message: '请输入处理人身份证号' }],},],},
	{ label: '处理人姓名', componentType: 'input', decorator: ['clrxm',{rules: [{ required: true, message: '请输入处理人姓名' }],},],},
	{ label: '资源名称', componentType: 'select', decorator: ['zymc',{rules: [{ required: true, message: '请输入资源名称' }],},],options: [
		{
			value: 'cpu',
			label: 'cpu'
		}, {
			value: '内存',
			label: '内存'
		}, {
			value: '硬盘',
			label: '硬盘'
		}]},
	//
]
