export const listColumns = [
	{ key: 'name', label: '产品名称' },
	{ key: 'rq', label: '日期' },
	{ key: 'syxt', label: '销售额（元）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '日期',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '日期', componentType: 'date', decorator: ['rq',{rules: [{ required: true, message: '请输入日期' }],},],},
	{ label: '销售额（元）', componentType: 'inputNumber', decorator: ['syxt',{rules: [{ required: true, message: '请输入销售额（元）' }],},],},
	{ label: '产品名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入产品名称' }],},],},
	//
]
