<template>
  <div class="auth-login">
    <div class="auth-login-mask">
      <div class="login-box">
      	<div class="logo">
	      	<!-- <img src="../../assets/images/auth/logo.png" alt /> -->
	      </div>
        <div class="welcome">
          <div class="log">光谷云管理后台</div>
        </div>
        <div class="input-box">
          <el-form
            :model="loginData"
            status-icon
            :rules="rules"
            ref="loginData"
            label-width="0px"
            class="demo-ruleForm"
          >
            <el-form-item prop="name">
              <el-input
                v-model.trim="loginData.name"
                placeholder="请输入用户名"
                prefix-icon="iconfont icon-shouji"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                onfocus="this.type='password'"
                placeholder="请输入登录密码"
                prefix-icon="iconfont icon-suo"
                v-model.trim="loginData.password"
                autocomplete="off"
                @keyup.enter.native="goLogin('loginData')"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="pass">
          <div class="remenber">
            <el-checkbox v-model="rememberChecked"></el-checkbox>
            <span @click="rememberChecked = !rememberChecked">记住密码</span>
          </div>
        </div>
        <div class="login-btn">
          <el-button type="primary" @click.native="goLogin('loginData')">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from "md5"
import { USER_INFO } from '@/utils/constant'
import { loginApi } from "@/api/authApi"
import { success } from "@/utils/action"
import { encrypt, decrypt } from "@/utils/encryption"

export default {
  name: "Login",

  data() {
    let validatename = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error("请输入用户名"))
      } else {
        callback()
        // let reg = /^1[123456789]\d{9}$/
        // if (!reg.test(value)) {
        //   callback(new Error("请输入有效的手机号码"))
        // }else{
        //   callback()
        // }
      }
    }

    let validatePass = (rule, value, callback) => {
      if (!value.trim()) {
        callback(new Error("请输入密码"))
      } else {
        callback()
      }
    }

    return {
      rememberChecked: false,
      loginData: {
        name: "",
        password: ""
      },
      rules: {
        name: [{ validator: validatename, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: "blur" }]
      },
    };
  },

  mounted() {
    this.getCookie()
  },

  methods: {
    // 登录
    goLogin(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            name: this.loginData.name, 
            password: this.loginData.password
          }
          loginApi(params).then( (rst) => {
            console.log('rst:', rst)
            const userResourceList = [
              {
                "id": 1,
                "parentId": null,
                "name": "系统总览",
                "code": "homeIndex",
                "icon": "el-icon-s-home",
                "url": "/",
                "isInNavigation": true,
                "weight": 0,
                "permissions": [],
                "children": []
              },
              {
                "id": 2,
                "parentId": null,
                "name": "运营中心",
                "code": "operationIndex",
                "icon": "el-icon-s-custom",
                "url": "/operation",
                "isInNavigation": true,
                "weight": 3,
                "children": []
              },
              {
                "id": 3,
                "parentId": null,
                "name": "资源中心",
                "code": "resourceIndex",
                "icon": "el-icon-s-cooperation",
                "url": "/resource",
                "isInNavigation": true,
                "weight": 1,
                "permissions": [],
                "children": []
              },
              {
                "id": 4,
                "parentId": null,
                "name": "业务中心",
                "code": "businessIndex",
                "icon": "el-icon-s-shop",
                "url": "/business",
                "isInNavigation": true,
                "weight": 2,
                "permissions": [],
                "children": []
              },
              {
                "id": 5,
                "parentId": null,
                "name": "机房中心",
                "code": "engineRoomIndex",
                "icon": "el-icon-s-custom",
                "url": "/engineRoom",
                "isInNavigation": true,
                "weight": 3,
                "permissions": [],
                "children": []
              },
            ]
            // 判断记住密码复选框是否被勾选, 勾选则调用配置cookie方法, 否则清空Cookie
            if (this.rememberChecked == true) {
              this.setCookie(this.loginData.name, this.loginData.password, 7)
            } else {
              this.clearCookie()
            }

            localStorage.setItem(USER_INFO, JSON.stringify({...rst.data, userResourceList}))
            success('登录成功')
            this.$router.push(this.getFristAuthMenu(userResourceList))
          })
        }
      })
    },
    
    // 递归查找第一个有效的菜单
    getFristAuthMenu(arr){
      if(!arr || Object.prototype.toString.call(arr) !== '[object Array]' || !arr.length){
        return '/'
      }else{
        const { url, children } = arr[0]
        if(url.trim()){
          return url
        }else{
          this.getFristAuthMenu(children)
        }
      }
    },

    // 注册压面跳转
    toRegister() {
      this.$router.push("/auth/register")
    },

    // 忘记密码跳转页面
    getPass() {
      this.$router.push("/auth/reset")
    },
    
    // 记住密码 -- 设置cookie(paramas: 账号名，密码，保存天数)
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date()
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays)
      window.document.cookie = "name" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString()
      window.document.cookie = "password" + "=" + encrypt(c_pwd) + ";path=/;expires=" + exdate.toGMTString()
    },

    // 记住密码 -- 读取cookie
    getCookie() {
      if (document.cookie.length > 0) {
        this.rememberChecked = true;
        var arr = document.cookie.split("; ")
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("=")
          // 判断查找相对应的值
          if (arr2[0] == "name") {
            this.loginData.name = arr2[1]
          } else if (arr2[0] == "password") {
            this.loginData.password =decrypt(arr2[1])
          }
        }
      }
    },

    //记住密码 -- 清除cookie
    clearCookie: function() {
      this.setCookie("", "", -1)
    }
  }
};
</script>
<style lang="sass" scoped>
.auth-login 
  position: relative
  width: 100%
  height: 100%
  background-image: url("../../assets/images/auth/bg.jpg")
  background-size: 100% 100%
  .auth-login-mask 
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    height: 100%
    width: 100%
    background: rgba(0, 0, 0, 0.4)
  .login-box 
    display: flex
    align-items: center
    flex-direction: column
    width: 390px
    height: 330px
    background-color: #fff
    position: relative
    padding: 0 33px
    .logo
      margin: 30px 0 0px 0
      img
        width: 100%
    .welcome 
      width: 382px
      height: 23px
      .log 
        text-align: center
        color: #999
        font-size: 24px
      img 
        width: 100%
        height: 100%
    .input-box 
      width: 344px
      margin-top: 35px
    .pass 
      display: flex
      justify-content: space-between
      width: 344px
      height: 20px
      align-items: center
      color: #fff
      span 
        font-size: 14px
        font-weight: 400
        cursor: pointer
        color: #409EFF
        margin-left: 5px
        &.register-btn
          margin-right: 25px
    .login-btn 
      margin-top: 30px
      width: 344px
</style>
<style lang="sass">
.auth-login 
  .el-input__inner 
    border-radius: 0px
    background-color: #fff
    line-height: 39px
  .el-form-item 
    margin-bottom: 30px
  .el-button 
    width: 100%
    border-radius: 0px
  /*-----------------修改type=number的样式------------------*/
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button 
    -webkit-appearance: none
  input[type="number"] 
    -moz-appearance: textfield
</style>