export const listColumns = [
	{ key: 'cpu', label: 'CPU高频' },
	{ key: 'status', label: '处理状态' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '处理状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '已处理',
				label: '已处理'
			}, {
				value: '未处理',
				label: '未处理'
			}
		]
		
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	
]

export const formItemEdit = () => [
	{ label: '处理状态', componentType: 'select', decorator: ['status',{rules: [{ required: true, message: '请输入处理状态' }],},],options: [
		{
			value: '已处理',
			label: '已处理'
		}, {
			value: '未处理',
			label: '未处理'
		}
	]},
	{ label: 'CPU高频', componentType: 'inputNumber', decorator: ['cpu',{rules: [{ required: true, message: '请输入CPU高频' }],},],},
	//
]
