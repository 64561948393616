export const listColumns = [
	{ key: 'name', label: '设备名称' },
	{ key: 'sbbh', label: '设备编号' },
	{ key: 'zcsd', label: '正常湿度（°C)' },
	{ key: 'dqsd', label: '当前湿度（°C)' },
	{ key: 'zcwd', label: '正常温度（°C)' },
	{ key: 'dqwd', label: '当前温度（°C)' },
	{ key: 'status', label: '设备状态' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '设备名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '设备状态',
		componentType: 'select',
		decorator: [
			'status',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '在线',
				label: '在线'
			}, {
				value: '离线',
				label: '离线'
			}, {
				value: '警告',
				label: '警告'
			}
		]
	},

]

export const formItemEdit = () => [
	{ label: '正常湿度（°C)', componentType: 'inputNumber', decorator: ['zcsd', { rules: [{ required: true, message: '请输入正常湿度（°C)' }], },], },
	{ label: '当前湿度（°C)', componentType: 'inputNumber', decorator: ['dqsd', { rules: [{ required: true, message: '请输入当前湿度（°C)' }], },], },
	{ label: '正常温度（°C)', componentType: 'inputNumber', decorator: ['zcwd', { rules: [{ required: true, message: '请输入正常温度（°C)' }], },], },
	{ label: '当前温度（°C)', componentType: 'inputNumber', decorator: ['dqwd', { rules: [{ required: true, message: '请输入当前温度（°C)' }], },], },
	{
		label: '设备状态', componentType: 'select', decorator: ['status', { rules: [{ required: true, message: '请输入设备状态' }], },], options: [
			{
				value: '在线',
				label: '在线'
			}, {
				value: '离线',
				label: '离线'
			}, {
				value: '警告',
				label: '警告'
			}
		]
	},
	{ label: '设备名称', componentType: 'input', decorator: ['name', { rules: [{ required: true, message: '请输入设备名称' }], },], },
	{ label: '设备编号', componentType: 'input', decorator: ['sbbh', { rules: [{ required: true, message: '请输入设备编号' }], },], },
	//
]
