export const listColumns = [
	{ key: 'cc', label: '存储（%）' },
	{ key: 'nc', label: '内存（%）' },
	{ key: 'cpu', label: 'cpu（%）' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '存储（%）', componentType: 'inputNumber', decorator: ['cc',{rules: [{ required: true, message: '请输入存储' }],},],},
	{ label: '内存（%）', componentType: 'inputNumber', decorator: ['nc',{rules: [{ required: true, message: '请输入内存' }],},],},
	{ label: 'cpu（%）', componentType: 'inputNumber', decorator: ['cpu',{rules: [{ required: true, message: '请输入cpu' }],},],},
	//
]
