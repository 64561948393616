export const listColumns = [
	{ key: 'ykfzzl', label: '月客户增长率（%）' },
	{ key: 'yhkfzb', label: '月活客户占比（%）' },
	{ key: 'yckfs', label: '异常客户数' },
	{ key: 'yjkfs', label: '预警客户数' },
	{ key: 'sykfzs', label: '上云客户总数' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '月客户增长率', componentType: 'inputNumber', decorator: ['ykfzzl',{rules: [{ required: true, message: '请输入月客户增长率' }],},],},
	{ label: '月活客户占比', componentType: 'inputNumber', decorator: ['yhkfzb',{rules: [{ required: true, message: '请输入月活客户占比' }],},],},
	{ label: '异常客户数', componentType: 'inputNumber', decorator: ['yckfs',{rules: [{ required: true, message: '请输入异常客户数' }],},],},
	{ label: '预警客户数', componentType: 'inputNumber', decorator: ['yjkfs',{rules: [{ required: true, message: '请输入预警客户数' }],},],},
	{ label: '上云客户总数', componentType: 'inputNumber', decorator: ['sykfzs',{rules: [{ required: true, message: '请输入上云客户总数' }],},],},
	//
]
