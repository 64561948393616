<template>
  <div class="page-index">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="运营" name="0"></el-tab-pane>
      <el-tab-pane label="业务" name="1"></el-tab-pane>
      <el-tab-pane label="机房" name="2"></el-tab-pane>
    </el-tabs>
    <DataOperation v-if="activeTab === '0'" />
    <DataBusiness v-if="activeTab === '1'" />
    <DataEngineRoom v-if="activeTab === '2'" />
  </div>
</template>

<script>
import DataBusiness from "./dataBusiness";
import DataEngineRoom from "./dataEngineRoom";
import DataOperation from "./dataOperation";

export default {
  name: "HomeIndex",

  components: { DataBusiness, DataEngineRoom, DataOperation },

  data() {
    return {
      activeTab: '0',
    };
  },

  methods: {
  },
};
</script>