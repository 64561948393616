export const listColumns = [
	{ key: 'name', label: '产品名称' },
	{ key: 'useNumber', label: '已使用' },
	{ key: 'totalNumber', label: '总数' },
	{ key: 'rate', label: '使用率（%）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '产品名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		// options: [
		// 	{
		// 		value: '云主机',
		// 		label: '云主机'
		// 	}, {
		// 		value: '云硬盘',
		// 		label: '云硬盘'
		// 	}, {
		// 		value: '镜像',
		// 		label: '镜像'
		// 	}, {
		// 		value: 'VPC',
		// 		label: 'VPC'
		// 	}, {
		// 		value: '安全组',
		// 		label: '安全组'
		// 	}, {
		// 		value: '弹性公网IP',
		// 		label: '弹性公网IP'
		// 	}
		// ]
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '已使用', componentType: 'inputNumber', decorator: ['useNumber',{rules: [{ required: true, message: '请输入已使用' }],},],},
	{ label: '总数', componentType: 'inputNumber', decorator: ['totalNumber',{rules: [{ required: true, message: '请输入总数' }],},],},
	{ label: '产品名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入产品名称' }],},], },
	//
]
