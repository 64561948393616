<template>
  <div class="404">
	404
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>
