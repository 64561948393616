<template>
	<div class="businessYhgl-index">
		<div class="flex-center-column bg-white p10">
			<CommonForm
				type="query"
				size="small"
				labelWidth="70px"
				inline
				:formItemList="formItemSearch"
				:initData="initSearchData"
				@submit="onSearchSubmit"
				style="margin-top: 15px"
			></CommonForm>
			<el-button type="primary" size="small" icon="el-icon-plus" @click="add">新增</el-button>
		</div>

		<el-table ref="userTable" :data="list" v-loading="tableLoading" border>
			<el-table-column
				v-for="(item, index) in columns"
				:key="index"
				:prop="item.key"
				:label="item.label"
				align="center"
			/>
			<el-table-column label="操作" width="250" align="center">
				<template slot-scope="scope">
					<el-button size="mini" @click="edit(scope.row, 1)">编辑</el-button>
					<el-button size="mini" type="danger" @click="del(scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			v-if="total > 10"
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			layout="total, sizes,prev, pager, next,jumper"
			:current-page.sync="page"
			:page-size="pageSize"
			:page-sizes="[5, 10, 15]"
			:total="total"
		>
		</el-pagination>

		<el-drawer
			:title="drawerType ? '编辑' : '新增'"
			:visible.sync="drawerVisible"
			size="40%"
			destroy-on-close
			@close="hideDrawer"
			@closed="hideDrawerClosed"
			class="drawer-contain"
		>
			<div class="drawer-content">
				<CommonForm
					size="small"
					:formItemList="formItemEdit"
					:initData="initEditData"
					@submit="onEditSubmit"
				/>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { listColumns, formItemEdit, formItemSearch } from './setting.js'
import { getListApi, addApi, deleteApi, updateApi, getDetailApi } from './api'
import CommonForm from '@/components/CommonForm.vue'
import { formDataFormat } from '@/utils/common.js'

export default {
	name: 'BusinessYhglIndex',

	components: { CommonForm },

	data() {
		return {
			initSearchData: null,
			initEditData: null,

			drawerVisible: false,
			drawerType: 0,
			drawerBtnLoading: false,

			tableLoading: true,
			list: [],
			columns: listColumns,
			page: 1,
			pageSize: 10,
			total: null,
		}
	},

	computed: {
		formItemSearch() {
			return formItemSearch(this)
		},
		searchFieldsKeys() {
			return this.formItemSearch.map((item) => item.decorator[0])
		},
		formItemEdit() {
			return formItemEdit(this)
		},
		editFieldsKeys() {
			return this.formItemEdit.map((item) => item.decorator[0])
		},
	},

	created() {
		this.initSearchData = formDataFormat(this.searchFieldsKeys, {})
		this.getList()
	},

	methods: {
		async getList() {
			this.tableLoading = true
			const params = { ...this.initSearchData, page: this.page, pageSize: this.pageSize }
			console.log('params:', params)
			const {
				data: { list, total },
			} = await getListApi(params)
			this.list = list
			this.total = total
			this.tableLoading = false
		},

		handleSizeChange(val) {
			this.page = 1
			this.pageSize = val
			this.getList()
		},

		handleCurrentChange(val) {
			this.page = val
			this.getList()
		},

		del(row) {
			this.$confirm('是否确定删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				deleteApi(row.id).then(() => {
					this.$message({
						type: 'success',
						message: '删除成功!',
					})
					this.onSearchSubmit()
				})
			})
		},

		add() {
			this.drawerType = 0
			this.initEditData = formDataFormat(this.editFieldsKeys, {})
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
		},

		async edit(row) {
			this.drawerType = 1
			const { data } = await getDetailApi(row.id)
			console.log('data:', data)
			this.initEditData = { ...formDataFormat(this.editFieldsKeys, data), id: row.id }
			console.log('this.initEditData:', this.initEditData)
			this.drawerVisible = true
		},

		hideDrawer() {
			this.drawerVisible = false
		},

		hideDrawerClosed() {
			this.onSearchSubmit()
		},

		onSearchSubmit(val) {
			console.log('val:', val)
			this.page = 1
			this.pageSize = 10
			this.initSearchData = val || {}
			if(this.initSearchData.start_time) {
				this.initSearchData.start_time = this.initSearchData.start_time + ' 00:00:00'
				this.initSearchData.end_time = this.initSearchData.end_time + ' 23:59:59'
			}
			this.getList()
		},

		async onEditSubmit(values) {
			console.log('values:', values)
			if (!values) {
				this.drawerVisible = false
				return
			}

			this.drawerBtnLoading = true
			const api = values.id ? updateApi : addApi
			const params = { ...values }
			const { data } = await api(params, values.id)
			console.log('data:', data)
this.drawerVisible = false
this.$message.success(`${values.id ? '编辑' : '新增'}成功`)
			this.drawerBtnLoading = false
			this.onSearchSubmit()
		},
	},
}
</script>
