import store from "@/store/index.js";
//翻页组件对象
export const pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
  pageSizeOptions: ["10", "20", "50", "100"],
  showSizeChanger: true,
  showQuickJumper: true,
  showTotal: total => `共${total}条`
};
/**
 * 是否有权限
 * @param {String} key
 */
export function isAuth(key) {
  const permissions = store.getters['menu/permissions']
  return permissions.includes(key)
}
/**
 * 生成uuid
 */
export const getUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}
/**
 *延迟time毫秒
 * @param {Number} time 延迟毫秒
 * @returns {Promise}
 */
export const sleep = (time = 30) =>
  new Promise(resolve => {
    let timer = setTimeout(() => {
      resolve();
      clearTimeout(timer);
      timer = null;
    }, time);
  });
/**
 * 数组转换为树数据
 * @param {Array} data 被转换的数组
 * @returns {Array}
 */
export const treeFormat = (data, id = "id", pid = 'parentId') => {
  data.forEach(item => {
    item.children = data.filter(el => el[pid] === item[id]);
    if (!item.children.length) delete item.children;
  });
  return data.filter(item => item[pid] === '0');
};
/**
 * 从对象data中筛选出数组keys中包含的属性值
 * @param {Array} keys 提供需要的属性key值
 * @param {Object} data 被筛选的对象
 * @returns {Object}
 */
export const formDataFormat = (keys, data) =>
  keys.reduce(
    (res, item) => ({
      ...res,
      [item]: data[item]
    }),
    {}
  );
/**
 * 在数组options中通过value来获取label值
 * @param {String｜Number} value 筛选的key值
 * @param {Array} options 需要筛选的数组
 * @returns {String}
 */
export const getLabelByValue = (value, options = []) =>
  options.find(item => item.value === value)?.label;
/**
 *检测value值是否为空
 * @param {Any} value 被检测的值
 * @returns {Boolean}
 */
export const isEmpty = value => [undefined, null, ""].includes(value);