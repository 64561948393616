<template>
  <div class="page-index">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="设备管理" name="0"></el-tab-pane>
      <el-tab-pane label="设备状态" name="1"></el-tab-pane>
      <el-tab-pane label="工单管理" name="2"></el-tab-pane>
      <el-tab-pane label="机房管理" name="3"></el-tab-pane>
    </el-tabs>
    <engineRoomSbgl v-if="activeTab === '0'" />
    <engineRoomSbzt v-if="activeTab === '1'" />
    <engineRoomGdgl v-if="activeTab === '2'" />
    <engineRoomJfgl v-if="activeTab === '3'" />
  </div>
</template>

<script>
import engineRoomGdgl from "./engineRoomGdgl";
import engineRoomJfgl from "./engineRoomJfgl";
import engineRoomSbgl from "./engineRoomSbgl";
import engineRoomSbzt from "./engineRoomSbzt";

export default {
  name: "HomeIndex",

  components: { engineRoomGdgl, engineRoomJfgl, engineRoomSbgl, engineRoomSbzt },

  data() {
    return {
      activeTab: '0',
    };
  },

  methods: {
  },
};
</script>