export const listColumns = [
	{ key: 'ip', label: '客户IP' },
	{ key: 'name', label: '资源名称' },
	{ key: 'yjcd', label: '预警程度' },
	{ key: 'sysj', label: '剩余时间（h）' },
	{ key: 'syl', label: '资源使用量（%）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '预警程度',
		componentType: 'select',
		decorator: [
			'yjcd',
			{
				rules: [{ required: false, message: '' }],
			},
		],
		options: [
			{
				value: '高',
				label: '高'
			}, {
				value: '中',
				label: '中'
			}, {
				value: '低',
				label: '低'
			}
		]
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '预警程度', componentType: 'select', decorator: ['yjcd',{rules: [{ required: true, message: '请输入预警程度' }],},],options: [
		{
			value: '高',
			label: '高'
		}, {
			value: '中',
			label: '中'
		}, {
			value: '低',
			label: '低'
		}
	]},
	{ label: '剩余时间（h）', componentType: 'inputNumber', decorator: ['sysj',{rules: [{ required: true, message: '请输入剩余时间（h）' }],},],},
	{ label: '资源使用量（%）', componentType: 'inputNumber', decorator: ['syl',{rules: [{ required: true, message: '请输入资源使用量（%）' }],},],},
	{ label: '资源名称', componentType: 'input', decorator: ['name',{rules: [{ required: true, message: '请输入资源名称' }],},],},
	{ label: '客户IP', componentType: 'input', decorator: ['ip',{rules: [{ required: true, message: '请输入客户IP' }],},],},
	//
]
