export const listColumns = [
	{ key: 'income', label: '光谷云收入（万元）' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '光谷云收入（万元）', componentType: 'inputNumber', decorator: ['income',{rules: [{ required: true, message: '请输入光谷云收入（万元）' }],},],},
	//
]
