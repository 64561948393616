export const listColumns = [
	{ key: 'dwmc', label: '单位名称' },
	{ key: 'zyxf', label: '资源消费（元）' },
	{ key: 'address', label: '详细地址' },
	{ key: 'ssdq', label: '所属地区' },
	{ key: 'create_time', label: '创建时间' },
	//
]

export const formItemSearch = () => [
	{
		label: '单位名称',
		componentType: 'input',
		decorator: [
			'dwmc',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '资源消费（元）', componentType: 'inputNumber', decorator: ['zyxf',{rules: [{ required: true, message: '请输入资源消费（元）' }],},],},
	{ label: '详细地址', componentType: 'input', decorator: ['address',{rules: [{ required: true, message: '请输入详细地址' }],},],},
	{ label: '所属地区', componentType: 'input', decorator: ['ssdq',{rules: [{ required: true, message: '请输入所属地区' }],},],},
	{ label: '单位名称', componentType: 'input', decorator: ['dwmc',{rules: [{ required: true, message: '请输入单位名称' }],},],},
	//
]
