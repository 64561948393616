export const listColumns = [
	{ key: 'ts', label: '提示' },
	{ key: 'zc', label: '正常' },
	{ key: 'yz', label: '严重' },
	{ key: 'zy', label: '重要' },
	//
]

export const formItemSearch = () => [
	{
		label: '名称',
		componentType: 'input',
		decorator: [
			'name',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
	{
		label: '创建时间',
		componentType: 'daterange',
		decorator: [
			'createTime',
			{
				rules: [{ required: false, message: '' }],
			},
		],
	},
]

export const formItemEdit = () => [
	{ label: '严重', componentType: 'inputNumber', decorator: ['yz',{rules: [{ required: true, message: '请输入严重' }],},],},
	{ label: '重要', componentType: 'inputNumber', decorator: ['zy',{rules: [{ required: true, message: '请输入重要' }],},],},
	{ label: '提示', componentType: 'inputNumber', decorator: ['ts',{rules: [{ required: true, message: '请输入提示' }],},],},
	{ label: '正常', componentType: 'inputNumber', decorator: ['zc',{rules: [{ required: true, message: '请输入正常' }],},],},
	//
]
